@font-face {
    font-family: AdelleSans;
    font-weight: 700;
    src: url("./assets/fonts/AdelleSans-Bold.otf") format("opentype");
}

@font-face {
    font-family: AdelleSans;
    font-weight: 500;
    src: url("./assets/fonts/AdelleSans-Semibold.otf") format("opentype");
}

@font-face {
    font-family: AdelleSans;
    font-weight: 400;
    src: url("./assets/fonts/AdelleSans-Regular.otf") format("opentype");
}

@font-face {
    font-family: AdelleSans;
    font-weight: 300;
    src: url("./assets/fonts/AdelleSans-Light.otf") format("opentype");
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a {
  color: #4A90E2;
  text-decoration: none;
}
body {
  color: #333;
  font-family: 'Roboto', sans-serif;
  height: 100%;
  -webkit-font-smoothing: antialiased;
}
input {
  background: #F1F1F1;
  border: none;
  border-bottom: 1px solid #EEE;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 33px;
  margin-bottom: 5px;
  padding-left: 10px;
  transition: all 0.2s;
  width: 100%;
}
input:focus {
  background: #FFF;
  border-bottom: 1px solid #333;
  outline: none;
}
input:hover {
  background: #FFF;
  border-bottom: 1px solid #AAA;
}
button,
input[type=submit] {
  background: #777;
  border: none;
  color: #FFF;
  cursor: pointer;
  display: block;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  height: 33px;
  margin-bottom: 5px;
  padding-left: 0;
  transition: all 0.2s;
  width: 100%;
}
button:hover,
input[type=submit]:hover {
  background: #555;
}
form {
  margin-bottom: 10px;
  padding: 0 10px;
}
h1 {
  font-size: 18px;
  font-weight: 300;
  margin-bottom: 10px;
}
p {
  margin-bottom: 5px;
}
p span {
  font-weight: 700;
}
.error {
  color: #D0011B;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
.success {
  color: #7ED321;
  font-size: 14px;
  padding: 5px;
  text-align: center;
}
.spinner {
  margin: 10px;
}
.three-bounce > div {
  background: #CCC;
}
.Home-section {
  margin-top: 10px;
  text-align: center;
}

.Saml-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  background-color: white;
}

.Saml-button {
  width: 343px;
  height: 48px;
  padding: 8px 16px 8px 16px;
  box-shadow: 0px 4px 4px 0px #7090B040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 20px / 50%;
  background-color: #50aced;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.Saml-button:hover {
  background-color: #0779B4;
   box-shadow: inset 0px 4px 8px 2px rgba(0, 0, 0, 0.25);
  transform: translateY(5px);
}

.Saml-button:active {
  background-color: #0779B4;
   box-shadow: inset 0px 8px 4px 2px rgba(0, 0, 0, 0.25);
  transform: translateY(5px);
}

.Saml-icon {
  margin-left: 15px;
}

.Saml-text {
  margin-right: 45px;
  font-size: 18px;
  font-weight: 600;
}

